<template>
  <!-- profile css 사용 -->
  <div class="container profile">
    <div
      class="profile-images"
      :style="{
        width: `${$store.state.app.width}px`,
        height: `${$store.state.app.width/16*10.5}px`,
      }"
      >
      <daum-map :x="offerData.geoX" :y="offerData.geoY" :width="`${$store.state.app.width}px`" :height="`${$store.state.app.width/16*10.5}px`" />
    </div>

    <div class="profile-header">
      <div class="profile-btn">
        <icon class="icon ui-btn share" src="img/common/share.png" width="3.2rem" height="3.7rem" :cover="false" @click="() => offerShare()" />
        <!-- <template v-if="userData.category === 'artist'">
          <icon class="ui-btn like" @click="() => setLike(false)" v-if="offerData.artistFav  > 0" src="img/common/liked2.png" width="3.7rem" height="3.7rem" :cover="false" />
          <icon class="ui-btn like" @click="() => setLike(true)" v-else src="img/common/like.png" width="3.7rem" height="3.7rem" :cover="false" />
        </template> -->
      </div>
      <div class="profile-badge" v-if="isEnd || isDirect || offerData.newOffer === 'y' || (!isEnd && dDay >= 0)">
        <!-- <div class="badge-read" v-if="isRead">읽음</div> -->
        <div class="badge-hot" v-if="!!offerData.userReferrer">H</div>
        <div class="badge-new" v-if="isArtist && !isEnd && offerData.newOffer === 'y'">N</div>
        <div class="badge-1-1" v-if="isDirect">1 : 1</div>
        <div class="badge-end" v-if="isEnd">마감</div>
        <div class="badge-dDay" v-if="!isEnd && dDay >= 0">{{dDay > 0 ? `D - ${dDay}` : '오늘마감'}}</div>
      </div>
      <div class="profile-name">{{$lib.t_genre(offerData.genre)}}<small>|</small>{{offerData.eventType}}</div>
      <div class="profile-genre-location color-lavender" style="margin: 1rem 0 2.3rem;font-size: 2.3rem;font-weight:500">
        {{ $lib.moneyRangeFormat(offerData) }}
      </div>
      <!-- TODO: isDev -->
      <!-- 멤버십 관련 내용 -->
      <!-- <div class="profile-genre-location">
        <template v-if="isArtist && isDev">
          <span class="p-0" :style="{ color: '#5A5A5A' }">지원자 <span class="p-0" :style="{ color: '#A059B6' }">{{offerData.recCount > 7 ? 7 : offerData.recCount || 0}}/7</span>명</span>
        </template>
        <template v-else>
          지원자 {{$lib.addComma(offerData.recCount) || 0}}명
        </template>
      </div> -->
      <div class="profile-viewCount" v-if="offerData.viewCount > 0 && !isDirect">
        <span>{{$lib.addComma(offerData.viewCount)}}명</span>이 봤고, <span>{{$lib.addComma(offerData.recCount) || 0}}</span>명이 지원했습니다.
      </div>
    </div>

    <div class="ui-border-line ui-h-1" style="height: 10px" :style="{marginTop: '3rem'}" />

    <template v-if="isArtist && !isNewMembershipUser && isApprovedProfile">
      <div :style="{ margin: '0 -3rem' }">
        <img
          :src="$lib.imgUrl('img/offer/memnership-banner-offer.png')"
          width="100%"
          @click="$router.push('/new-membership')"
        />
      </div>

      <div class="ui-border-line ui-h-1" style="height: 10px" />
    </template>

    <div class="ui-border-line ui-h-0 ui-mb-2"></div>

    <div
      v-if="isShowProPriceRange"
      class="text-center"
    >
      <div
        :style="{
          fontSize: '17px',
          color: '#909090',
          marginBottom: '10px',
        }"
      >
        의뢰 지원금액 현황
      </div>
      <div
        class="ui-weight-5"
        :style="{
          fontSize: '21px',
          color: '#000',
        }"
      >
        {{$lib.addComma(offerData.minProPrice)}}원 ~ {{$lib.addComma(offerData.maxProPrice)}}원
      </div>

      <div class="ui-border-line ui-h-1 ui-mt-2 ui-mb-2" style="height: 10px" />
    </div>

    <div class="offer-addinfo" v-if="offerData.wgender || offerData.wage || offerData.wtransCost">
      <ul>
        <li v-if="offerData.wgender">
          <template v-if="offerData.wgender === '여성'">
            <icon src="img/offer/woman.png" width="5rem" height="5rem" :cover="false" origin-url />
            여성 선호
          </template>
          <template v-else-if="offerData.wgender === '남성'">
            <icon src="img/offer/man.png" width="5rem" height="5rem" :cover="false" origin-url />
            남성 선호
          </template>
          <template v-else>
            <icon src="img/offer/gender.png" width="5rem" height="5rem" :cover="false" origin-url />
            성별 무관
          </template>
        </li>
        <li v-if="offerData.wage">
          <icon src="img/offer/age.png" width="5rem" height="5rem" :cover="false" origin-url />
          <template v-if="!offerData.wage || offerData.wage === '무관'">나이 무관</template>
          <template v-else>{{offerData.wage}}</template>
        </li>
        <li v-if="offerData.wtransCost">
          <icon src="img/offer/fee.png" width="5rem" height="5rem" :cover="false" origin-url />
          <template v-if="offerData.wtransCost === '포함'">교통비 포함</template>
          <template v-else>교통비 별도</template>
        </li>
      </ul>

      <div class="ui-border-line ui-h-1 ui-mt-2" style="height: 10px"></div>
    </div>


    <div class="profile-info">
      <div class="info-block" v-if="offerData.playDate">
        <ul>
          <li>진행일시</li>
          <li>{{playDate(offerData.playDate)}}</li>
        </ul>
        <div class="ui-border-line" style="margin: 0px;"></div>
      </div>

      <div class="info-block" v-if="offerData.address">
        <ul>
          <li>위치</li>
          <li>{{offerData.address}}</li>
        </ul>
        <div class="ui-border-line" style="margin: 0px;"></div>
      </div>

      <div class="info-block" v-if="offerData.playTime">
        <ul>
          <li>필요 시간</li>
          <li>{{offerData.playTime}}</li>
        </ul>
        <div class="ui-border-line" style="margin: 0px;"></div>
      </div>

      <div class="info-block" v-if="offerData.productType">
        <ul>
          <li>판매할 상품</li>
          <li>{{offerData.productType}}</li>
        </ul>
        <div class="ui-border-line" style="margin: 0px;"></div>
      </div>

      <template v-if="offerData.genre === '모델'">
        <div class="info-block" v-if="offerData.playLocation">
          <ul>
            <li>촬영 장소</li>
            <li>{{offerData.playLocation}}</li>
          </ul>
          <div class="ui-border-line" style="margin: 0px;"></div>
        </div>

        <div class="info-block" v-if="offerData.whairStyle">
          <ul>
            <li>헤어스타일링</li>
            <li>{{offerData.whairStyle}}</li>
          </ul>
          <div class="ui-border-line" style="margin: 0px;"></div>
        </div>

        <div class="info-block" v-if="offerData.wmakeUp">
          <ul>
            <li>메이크업</li>
            <li>{{offerData.wmakeUp}}</li>
          </ul>
          <div class="ui-border-line" style="margin: 0px;"></div>
        </div>
      </template>

      <template v-if="offerData.genre === '노래'">
        <div class="info-block" v-if="offerData.wsong">
          <ul>
            <li>원하는 노래</li>
            <li>{{offerData.wsong}}</li>
          </ul>
          <div class="ui-border-line" style="margin: 0px;"></div>
        </div>

        <div class="info-block" v-if="offerData.wmic">
          <ul>
            <li>음향장비</li>
            <li>{{offerData.wmic}}</li>
          </ul>
          <div class="ui-border-line" style="margin: 0px;"></div>
        </div>
      </template>

      <template v-if="offerData.genre === 'MC'">
        <div class="info-block" v-if="offerData.wmcStyle">
          <ul>
            <li>진행 스타일</li>
            <li>{{offerData.wmcStyle.split('|').join(', ')}}</li>
          </ul>
          <div class="ui-border-line" style="margin: 0px;"></div>
        </div>
      </template>
    </div>

    <div class="ui-border-line" style="height: 10px"></div>

    <template v-if="offerData.detail">
      <div class="profile-teamDesc">
        <h4>상세 정보</h4>{{offerData.detail}}
      </div>
      <div class="ui-border-line" style="height: 10px"></div>
    </template>

    <!-- 결제 추가 후 사용 -->
    <!-- <div class="profile-teamDesc">
      <h4>유의사항</h4>
      <p class="p-darkgray">- 결제 금액은 행사 종료전까지 안전하게 보관됩니다.</p>
      <p class="p-darkgray">- 대금은 행사 종료 후, '공연비 지급'을 결정하시거나 또는 행사 종료 3일 후 자동으로 지급 됩니다.</p>
    </div>

    <div class="ui-border-line ui-h-0 ui-mb-4"></div> -->

    <template v-if="!isPreview && (platform !== 'webapp' || $store.getters.isLAN) && userData.useridx > 0">
      <template v-if="userData.category === 'artist'">
        <footer-box
          v-if="
            hasApply && isMember || isPaidOffer
          "
          single
          submit-text="통화하기"
          :submit-cb="() => callToUser()"
        >
          <icon
            slot="submitLeftSlot"
            src="img/common/call2.png"
            width="3rem"
            height="3rem"
            :cover="false"
            :style="{
              display: 'inline-block',
              marginBottom: '-6px',
            }"
          />
        </footer-box>
        <!-- <footer-box
          v-if="
            hasApply && isMember || isPaidOffer
          "
          :single="false"
          btn-text="통화하기"
          :btn-cb="() => callToUser()"
          :btn-disabled="false"
          submit-text="대화하기"
          :submit-cb="() => createMsgRoom()"
          :submit-disabled="false"
        >
          <icon
            slot="btnLeftSlot"
            src="img/common/call.png"
            width="3rem"
            height="3rem"
            :cover="false"
            :style="{
              display: 'inline-block',
              marginRight: '8px',
              marginBottom: '-6px',
            }"
          />
          <icon
            slot="submitLeftSlot"
            src="img/common/chat-ico.png"
            width="3rem"
            height="3rem"
            :cover="false"
            :style="{
              display: 'inline-block',
              marginRight: '14px',
              marginBottom: '-7px',
            }"
          />
        </footer-box> -->
        <!-- 지원완료 -->
        <footer-box
          v-else-if="hasApply"
          submit-text="지원완료"
          submit-disabled
        />
        <!-- 1:1 오퍼 -->
        <footer-box
          v-else-if="isDirect"
          :single="false"
          btn-text="거절하기"
          :btn-cb="() => gotoReject()"
          :btn-disabled="isEnd"
          submit-text="지원하기"
          :submit-cb="() => gotoApply()"
          :submit-disabled="isEnd"
        />
        <!-- 지원하기 -->
        <footer-box
          v-else
          submit-text="지원하기"
          :submit-cb="() => openProfileSelectPopup()"
          :submit-disabled="isEnd"
        />
      </template>
      <template v-else>
        <footer-box
          v-if="isRegisteredOffer"
          submit-text="이 내용으로 견적 요청하기"
          :submit-cb="() => postDirectOffer()"
        >
        </footer-box>
        <footer-box
          v-else-if="isMyOffer"
          :submit-text="!isEnd ? '모집 마감하기' : '마감되었습니다'"
          :submit-cb="() => putOffer()"
          :submit-disabled="isEnd"
        >
        </footer-box>
      </template>
    </template>
    <template v-else-if="isGuest">
      <footer-box
        submit-text="헤이비글 앱에서 보기"
        :submit-cb="() => gotoApp()"
        >
      </footer-box>
    </template>
    <!-- 지원 프로필 선택 팝업 -->
    <page-modal
      ref="pageModal"
      :title="'지원 프로필 선택'"
      :class="'close-right'"
      >
      <div slot="body" class="page-modal-body">
        <ul class="profile-select-list">
          <li v-for="(row, key) in aprvProfiles" :key="key">
            <input type="radio" :id="'profile'+(key + 1)" class="sr-only" name="profileChange" v-model="pickArtist" :value="row" :disabled="applyArtists.includes(row.artistidx) || !checkCondition(row.gender)">
            <label :for="'profile'+(key + 1)">
              <div class="img-area">
                <div class="img" :style="{
                    backgroundColor: 'lightgrey',
                    backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl || row.imgUrl1)})`
                  }"
                >
                </div>
              </div>
              <div class="profile-info">
                <div class="userNickName">{{row.teamName}}</div>
                <div class="genre">{{row.genre}}</div>
              </div>
              <div class="radio-btn"></div>
            </label>
          </li>
        </ul>
      </div>
      <footer-box
        single
        submit-text="선택 완료"
        :submitCb="() => gotoApply()"
      >
      </footer-box>
    </page-modal>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import DaumMap from '@/components/common/DaumMap'
import deepLink from '@/lib/deepLink.js'
import PageModal from '@/components/common/PageModal.vue'

export default {
  name: 'Offer',
  components: {
    Icon,
    FooterBox,
    DaumMap,
    PageModal
  },
  metaInfo() {
    const { genre, eventType } = this.offerData
    const title = genre + ', ' + eventType
    const imgUrl = this.mapImgUrl
    return {
      title: title,
      titleTemplate: '헤이비글 - %s',
      meta: [
        {
          property: 'og:title',
          content: title,
          template: '헤이비글 - %s',
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: title,
          template: '%s 의뢰를 공유합니다.',
          vmid: 'og:description'
        },
        {
          property: 'og:url',
          content: location.href,
          vmid: 'og:url'
        },
        {
          property: 'og:image',
          content: imgUrl,
          vmid: 'og:image'
        },
      ]
    }
  },
  data() {
    return {
      offerData: {},
      mapImgUrl: '',
      pickArtist: {},
    }
  },
  computed: {
    artistAccountInfo() {
      return this.$store.getters.getArtistAccountInfo
    },
    feeInformationAndAgreement() {
      return this.artistAccountInfo.agreeDate ? true : false;
    },
    profileList(){
      return this.$store.getters.getArtistProfiles;
    },
    aprvProfiles() {
      return this.profileList.filter(v => v.artistProfileStep == 3 );
    },
    isRead: {
      get() {
        return this.offerData.proClientReadDate > 0
      }
    },
    isEnd: {
      get() {
        const now = Math.floor(Date.now() / 1000)
        const end = Number(this.offerData.playDate || 0)

        if (now >= end || (!this.isPreview && ['ing', 'wait'].indexOf(this.offerData.nowState) === -1)) return true

        return false
      },
    },
    dDay: {
      get() {
        const now = moment(moment(new Date()).format('YYYY-MM-DD'))
        const end = moment(moment(Number(this.offerData.playDate) * 1000).format('YYYY-MM-DD'))
        return end.diff(now, 'days')
      },
    },
    isArtist: {
      get() {
        return this.userData.category === 'artist'
      },
    },
    hasApply: {
      get() {
        if (this.isDirect) {
          return this.offerData.recCount > 0
        }
        return this.offerData.proCreatedDate > 0 || /^\/artist\/my-offer/.test(this.$route.path)
      },
    },
    isPreview: {
      get() {
        return !this.$route.params.offerUid
      }
    },
    artistData() {
      return this.$store.state.user.artistData
    },
    isMember() {
      // if (this.artistData && Number(this.artistData.memberDate) > 0 && Number(this.artistData.memberDate) * 1000 > Date.now()) {
      //   return true
      // }
      return false
    },
    isNoProfile() {
      return this.aprvProfiles.length == 0
    },
    userData() {
      return this.$store.state.user.userData
    },
    playDate() {
      return (playDate) => {
        let date = moment(Number(playDate) * 1000)
        let noon = '오전'
        if (date.format('H') >= 12) noon = '오후'
        return date.format(`YYYY.MM.DD / ${noon} hh시 mm분`)
      }
    },
    platform() {
      return sessionStorage.getItem('platform') || 'webapp'
    },
    isDev() {
      return process.env.NODE_ENV !== 'production'
    },
    isGuest() {
      return !('useridx' in this.userData) || this.userData.useridx == 0
    },
    isMyOffer() {
      return this.userData.useridx == this.offerData.useridx
    },
    isDirect() {
      return this.offerData?.mode === 'direct'
    },
    isPaidOffer() {
      const condition = {
        default: [
          () => ['ing', 'selfEnd', 'autoEnd', 'cancel'].indexOf(this.offerData?.nowState) === -1,
          () => this.offerData?.payStatus === '00',
        ],
        artist: [
          () => this.userData?.artistidx == this.offerData?.artistidx,
        ],
        client: [
          () => this.userData?.useridx == this.offerData?.useridx,
          // () => this.artistidx === this.offerData?.artistidx,
        ],
      }
      if (condition.default.every(fn => fn())) {
        return condition[this.isArtist ? 'artist' : 'client'].every(fn => fn())
      }

      return false
    },
    isNewMembershipUser() {
      return this.$store.getters.isNewMembershipUser
    },
    isApprovedProfile() {
      return this.$store.getters.isApprovedProfile
    },
    isShowProPriceRange() {
      if (!this.hasApply) {
        return false
      }
      if (!this.isNewMembershipUser) {
        return false
      }
      if (!this.offerData?.minProPrice) {
        return false
      }
      if (!this.offerData?.maxProPrice) {
        return false
      }
      return true
    },
    isRegisteredOffer() {
      return this.$route.name === 'ClientRegisteredOfferDetail'
    },
    applyArtists(){
      let artistidxList
      if(this.offerData.artistidxList){
        artistidxList = this.offerData.artistidxList.map(item => item.artistidx)
      }
      return artistidxList || [];
    }
  },
  created() {
    if (!this.$store.getters.isLAN && this.platform === 'webapp' && !this.$route.params.offerUid) {
      this.$router.push('/logout')
      return
    }

    if (this.isPreview) {
      this.offerData = this.$store.state.postOffer
      return
    }

    this.getOffer()

    // 로컬용 읽음처리
    let localStorageKey = `read:offer:${this.$route.params.offerUid}`
    if (this.$route.params.offerUid && !localStorage.getItem(localStorageKey)) {
      localStorage.setItem(localStorageKey, ''+Date.now())
      this.$root.$emit(localStorageKey)
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('setGnbLeftBtn', null)
      vm.$store.commit('setGnbLeftBtnCb', null)
      vm.getArtistProfiles()
      vm.getAccountInfo();
    })
  },
  mounted() {
    this.getAccountInfo();
  },
  methods: {
    getAccountInfo() {
      const req = {
        method: 'get',
        url: `/artist/accountInfo`,
      }

      return this.$http(req)
        .then(({ data }) => {
            log(...Object.values(req), data)

            const resultData = this.$lib.resultCheck(data.resultData)
            if (resultData.success) {
                let response = data.response || {}
                if(response){
                    this.$store.commit('setArtistAccountInfo', response);
                }
            } else {
                this.$alert(resultData)
            }
            return
        })
        .catch((e) => {
            log.error(e)
            return
        })
    },
    getArtistProfiles() {
      this.$store.dispatch('getProfileList')
    },
    getOffer() {
      const req = {
        method: 'get',
        url: `/client/offer/${this.$route.params.offerUid}`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || {}

            if (!offerData.offerUid) {
              this.$router.back()
              return
            }

            if (this.isArtist && offerData.mode === 'direct' && offerData.artistidx !== this.userData.artistidx) {
              this.$router.back()
              return
            }

            this.offerData = offerData
            this.getMapImgUrl()
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },

    putOffer(sta = 'selfEnd') {
      if (!this.isMyOffer) return
      let confirmCb = (modal) => {
        modal.hide()

        const req = {
          method: 'put',
          url: `/client/offer/${this.$route.params.offerUid}/state/${sta}`,
        }

        this.$http(req)
          .then(({ data }) => {
            log(...Object.values(req), data)

            const resultData = this.$lib.resultCheck(data.resultData)

            if (resultData.success) {
              this.offerData.nowState = 'selfEnd'
              this.$toast('모집이 마감되었습니다')
            } else {
              this.$alert(resultData)
            }
          })
          .catch(log.error)
      }

      this.$store.commit('setModal', {
        show: true,
        title: `모집을 마감하시겠어요?`,
        body: `마감 시 등록한 의뢰는 비공개되며\n더이상의 지원은 받을 수 없습니다.`,
        confirm: `네 마감할래요.`,
        confirmCb,
      })
    },

    setLike(isPost) {
      if (this.isPreview || !this.isArtist) return

      const req = {
        method: isPost ? 'post' : 'delete',
        url: `/artist/fav/${this.$route.params.offerUid}`,
        loading: false,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.offerData.artistFav = isPost ? Math.floor(Date.now() / 1000) : 0
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    offerShare() {
      this.$runToApp({
        appCb: () => location.href = `hbscheme://offerShare?url=${encodeURI(this.$lib.webUrl('/web/noffer/' + this.$route.params.offerUid))}`,
        webCb: () => {
          this.$copyText(this.$lib.webUrl('/web/noffer/' + this.$route.params.offerUid))
            .then(() => {
              this.$toast('URL이 복사되었습니다.')
            })
        },
      })
    },
    openProfileSelectPopup() {
      console.log('openProfileSelectPopup')

      let profiles = this.aprvProfiles.filter( v=> !this.applyArtists.includes(v.artistidx) && this.checkCondition(v.gender))
      if(profiles.length > 0){
        this.pickArtist = profiles[0];
        if(profiles.length  == 1){
          this.gotoApply();
        }
      }

      if (this.isNoProfile) {
        this.$store.commit('setModal', {
          show: true,
          title: '프로필을 먼저 등록해주세요!',
          body: '지원하기는 프로필이 등록된 후에 이용할 수 있습니다.',
          useCancel: false,
        })
        return false;
      }

      if (!this.feeInformationAndAgreement) {
        let isConfirm = false
        this.$store.commit('setModal', {
          show: true,
          title: '약관 동의가 필요해요.',
          body: `헤이비글에서 전문가로 활동하려면\n전문가 이용규정 확인 및\n약관 동의가 필수입니다.\n지금 바로 확인하시겠어요?`,
          useCancel: true,
          cancel: '닫기',
          confirm: '확인하기',
          confirmCb(modal) {
            isConfirm = true
            modal.hide()
          },
          hiddenCb: () => {
            if (isConfirm) {
              this.$router.push({
                path: '/artist/my-profile/fee-information-and-agreement/1',
                query: {
                  pageReferer: this.$route.path,
                },
              })
            }
          },
        })
        return
      }
      
      this.$refs.pageModal.show();
      
    },
    gotoApply() {
      // 선택팝업 확인 메소드에서 검증하도록 이동 
      if (!this.checkCondition(this.pickArtist.gender)) {
        this.$toast('선호 성별이 달라 지원할 수 없습니다.')
        return
      }

      let applyUrl = {
        path: `/offer/${this.$route.params.offerUid}/apply`,
        query: {artistidx: this.pickArtist.artistidx}
      }

      this.$router.push(applyUrl)
    },
    checkCondition(gender){
      if (
        (gender && gender !== 'g' ) && 
        ( this.offerData.wgender === '여성' && gender !== 'f' ||
         this.offerData.wgender === '남성' && gender !== 'm'
        )
      ){
        return false;
      }
      return true;
    },
    getMapImgUrl() {
      setTimeout(() => {
        const mapImg = document.querySelector('#page > div > div.profile-images > div > div.map-container > a > img')
        if (mapImg) {
          this.mapImgUrl = mapImg.src
        } else {
          this.getMapImgUrl()
        }
      }, 500)
    },
    callToUser() {
      gtag('event', 'pro2user_call_b')
      // this.callCheck(this.artistData.artistidx)

      if (this.offerData.phone) {
        location.href = 'tel:' + this.offerData.phone
      } else {
        this.$toast('전화번호가 등록되지 않은 회원입니다.')
      }
    },
    // callCheck(artistidx) {
    //   const req = {
    //     method: 'get',
    //     url: `/artist/telCount/${artistidx}`,
    //   }
    //   this.$http(req)
    // },
    createMsgRoom() {
      // if ([this.offerData?.platform, this.offerData?.userPlatform].some(val => /^web_(PC|M)$/.test(val))) {
      //   this.$store.commit('setModal', {
      //     show: true,
      //     body: `
      //       <span style="font-size: 24px;font-weight: 600;color: #000;">채팅을 사용하지 않는<br>회원입니다.</span>

      //       헤이비글 담당자에게
      //       카카오톡 연결을 요청하세요.`,
      //     textAlign: 'text-center',
      //     confirm: '확인',
      //     confirmCb: modal => {
      //       modal.hide()
      //     },
      //     noBorder: true,
      //     useCancel: false,
      //   })
      //   return
      // }

      const req = {
        method: 'post',
        url: `/chat/${this.$route.params.offerUid}`,
        data: {
          useridx: this.offerData.useridx,
          targetUseridx: this.artistData.artistidx,
          targetTeamName: this.artistData.teamName,
        }
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$router.push(`/message/${this.$route.params.offerUid}/${this.artistData.artistidx}`)
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    gotoApp() {
      deepLink(`/offer/${this.$route.params.offerUid}`)
    },
    gotoReject() {
      this.$router.push(`/offer/reject/${this.offerData?.offerUid}`)
    },
    async postDirectOffer() {
      const postOffer = this.$store.state?.postOffer ?? {}
      const originForm = Object.keys(postOffer).reduce((result, key) => {
        if (postOffer[key]) {
          result[key] = postOffer[key]
        }
        return result
      }, {})
      const form = await this.$store.dispatch('postOffer/dataFiltering', this.offerData ?? {})

      this.$store.commit('setPostOffer', {
        ...form,
        ...originForm,
      })

      this.$store
        .dispatch('postOffer/submit', this)
        .then((resultData) => {
            if (resultData.success) {
                this.$router.push('/offer/post/complete')
            } else {
                this.$alert(resultData)
            }
        })
        .catch(log.error)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.profile-images {
  margin: 0 -3rem;
  // width: 54rem;
  // height: 54 / 16 * 10.5rem;
  background-color: #ddd;
}

.offer-addinfo {
  ul {
    display: flex;
    justify-content: space-around;
    li {
      width: 30%;
      color: #000;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 500;
      .icon {
        margin: 1rem auto 1rem;
      }
    }
  }
}
.p-darkgray {
  color: #B6B6B6;
  font-size: 2rem;
  color: #5a5a5a;
}

.profile-select-list {
  // overflow-y: scroll;
  // max-height: 47.6rem;
  overflow-y: auto;
  height: calc(100vh - 31.5rem);
  margin: -3rem 0;
  li {
    & ~ li {
      margin-top: 1.2rem;
    }
    input {
      &:checked {
        & + label {
          background-color: #F7F8FE;
          .radio-btn {
            position: relative;
            width: 2.8rem;
            height: 2.8rem;
            &::before {
              border: 1px solid #AB54BB;
            }
            &::after {
              background-color: #AB54BB;
            }
          }
        }
      }
    }
    label {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin: 0;
      padding: 2.2rem 3rem;
      border-radius: 1.2rem;
      .img-area {
        position: relative;
        .img {
          overflow: hidden;
          flex-shrink: 0;
          width: 6.6rem;
          height: 6.6rem;
          border-radius: 11rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .new {
          position: absolute;
          top: 0;
          right: 2px;
          width: 1.2rem;
          height: 1.2rem;
          border: 2px solid #fff;
          border-radius: 50%;
          background-color: #F33D12;
        }
      }
      
      .profile-info {
        flex: 1;
        .userNickName {
            color: #000;
            font-size: 2rem;
            font-weight: 600;
        }
        .genre {
            margin-top: 0.5rem;
            color: #666666;
            font-size: 1.8rem;
        }
      }
    }
    .radio-btn {
      position: relative;
      width: 2.8rem;
      height: 2.8rem;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #A8A8A8;
        border-radius: 2.8rem;
        content: '';
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 2.8rem;
        transform: translate(-50%, -50%);
        content: '';
      }
    }
  }
}
</style>
